<template>
  <div class='addWrap'
       style="">
    <div class="blockWrap">
      <div class="title">考试信息</div>
      <el-form :model="form"
               ref="formRef"
               label-width="100px"
               :rules="rules">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item prop="test_name"
                          label="考试名称">
              <el-input placeholder="请输入"
                        v-model="form.test_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="paper_test_type_id"
                          label="考试类型">
              <el-select v-model="form.paper_test_type_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.paper_test_type_id"
                           :label="item.type_name"
                           :value="item.paper_test_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="grade_id"
                          label="考试年级">
              <el-select v-model="form.grade_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in gradeList"
                           :key="item.grade_id"
                           :label="item.grade_name"
                           :value="item.grade_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="subject_id"
                          label="考试科目">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         @change="getTeacher"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="start_time"
                          label="开始时间">
              <el-date-picker v-model="form.start_time"
                              style="width:100%"
                              @focus="$forbid"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="end_time"
                          label="结束时间">
              <el-date-picker v-model="form.end_time"
                              style="width:100%"
                              @focus="$forbid"
                              default-time="23:59:59"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="开始时间" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="paper_correct_type"
                          label="学生自评">
              <el-radio-group v-model="form.paper_correct_type"
                              @change='clearPaper'>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="blockWrap">
      <el-button type="primary"
                 style="margin-bottom:10px"
                 @click="checkPaperShowDialog">选择试卷</el-button>
      <el-table :data="list">
        <el-table-column prop="edu_paper_id"
                         label="ID"
                         width="100" />
        <el-table-column prop="paper_name"
                         label="试卷名称" />
        <el-table-column prop="subject_name"
                         label="科目"
                         width="80" />
        <el-table-column prop="template_name"
                         label="模板名称" />
        <el-table-column prop="paper_score"
                         label="总分"
                         width="100" />
        <el-table-column prop="sheet_status"
                         width="100"
                         label="答题卡状态">
          <template slot-scope="{row}">
            {{form.paper_correct_type==0?$sheetStatus[row.sheet_status]:$sheetStatus[row.sheet_status2]}}
          </template>
        </el-table-column>
        <el-table-column prop="opteration"
                         width="100"
                         label="操作">
          <template slot-scope="{row}">
            <div v-if="row.edu_paper_id">
              <div @click="toDetail(row)"
                   class="opertion_image_warp">
                <el-tooltip effect="dark"
                            content="详情"
                            placement="top-start">
                  <img src="@/assets/icons/icon-look@2x.png"
                       class="operyion_img">
                </el-tooltip>
              </div>
              <div @click="toExport(row)"
                   class="opertion_image_warp">
                <el-tooltip effect="dark"
                            content="制作答题卡"
                            placement="top-start">
                  <img src="@/assets/icons/export.png"
                       class="operyion_img">
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="blockWrap"
         v-show='form.paper_correct_type==0'>
      <!-- <el-tabs v-model="form.correct_type">
        <el-tab-pane label="整卷阅卷"
                     name="0">
        </el-tab-pane>
        <el-tab-pane label="按题目阅卷"
                     name="1">
        </el-tab-pane>
      </el-tabs> -->
      <div class="dropWrap">
        <el-dropdown>
          <span class="el-dropdown-link">
            {{labelList[form.correct_type]}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in labelList"
                              @click.native.prevent="setType(index)"
                              :key="index">{{item}}</el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <Whole ref="wholeRef"
             v-show="form.correct_type == '0'" />
      <Allot ref="allotRef"
             v-show="form.correct_type == '1'"
             :teacherList="teacherList" />

    </div>
    <div style="margin-top: -30px;margin-left: 15px;margin-bottom: 10px;">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-button type="primary"
                     style="margin-top:10px"
                     @click="submit">确认</el-button>
          <el-button type="primary"
                     style="margin-top:10px"
                     @click="preserve">保存</el-button>
        </el-col>
      </el-row>
    </div>
    <CheckPaper ref="checkPaperRef"
                :teacherList="teacherList" />
  </div>
</template>

<script>
import { getTypeList, getTeacherList, save, getInfo } from '@/api/exam.js'
import { getList } from '@/api/examType.js'
import { getGrade } from '@/api/grade.js'
import { getAllSubject } from '@/api/subject.js'
import CheckPaper from './checkPaper.vue'
import Whole from './whole.vue'
import Allot from './allot.vue'
import { getQuestionNumber } from '@/api/exam.js'

export default {
  data () {
    return {
      form: {
        paper_module: undefined,//组卷类型，1为自组组卷
        edu_paper_id: "",
        paper_test_type_id: "",
        start_time: '',
        correct_type: '0', //整卷批改，分题批改
        end_time: '',
        test_name: '',
        test_paper_id: '',
        grade_id: '',
        subject_id: '',
        // correct_type 等于0 时必传， 选择的批改老师ID集合
        correct_user_id: [],
        // correct_type 等于1 时必传
        paper_correct_data: [],
        // 1保存 0确定
        keep_id: 0,
        paper_correct_type: 0
      },
      labelList: {
        0: '整卷阅卷',
        1: '按题目阅卷'
      },
      rules: {
        paper_test_type_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        start_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        end_time: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        test_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      typeList: [],
      gradeList: [],
      subjectList: [],
      list: [],
      questionNumber: [],
      teacherList: [],
      paperTypeList: []
    }
  },
  components: {
    CheckPaper, Whole, Allot
  },
  mounted () {
    this.initList()
    let id = this.$route.query.id
    if (id) {
      this.getExamInfo(id)
    }
  },
  methods: {
    setType (index) {
      this.$set(this.form, 'correct_type', index + '')
    },
    async initList () {
      let params = {
        show_page: 0,
        show_status: 1
      }
      let data = await Promise.all([getList(params), getGrade(), getAllSubject()])
      this.typeList = data[0].data.list
      this.gradeList = data[1].data.list
      this.subjectList = data[2].data.subject.splice(1, data[2].data.subject.length)
      let res = await getTypeList()
      console.log('res', res)
      this.paperTypeList = res.data
    },
    checkPaperShowDialog () {
      this.$refs.checkPaperRef.paper_correct_type = this.form.paper_correct_type
      this.$refs.checkPaperRef.dialogVisible = true
    },
    setQN (data, arr = []) {
      let list = data.map(item => {
        let obj = {
          question_num: item,
          edu_user: []
        }
        if (arr.length) {
          let user = arr.filter(row => row.question_num == obj.question_num)
          if (user.length) {
            obj.edu_user = [...user]
          }
        }
        return obj
      })
      this.questionNumber = list
      this.$refs.allotRef.list = this.questionNumber
    },
    async getTeacher () {
      let params = {
        subject_id: this.form.subject_id
      }
      const { data } = await getTeacherList(params)
      this.teacherList = data
    },
    clearPaper () {
      // 切换是否自评时，清空试卷
      this.list = []
    },
    submit () {
      this.$refs.formRef.validate(async val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        if (this.list.length == 0) {
          this.$message.warning("请选择试卷")
          return
        }
        form.edu_paper_id = this.list[0].edu_paper_id
        // 当学生不自评
        if (this.form.paper_correct_type == 0) {
          if (this.form.correct_type == 0) {
            if (this.$refs.wholeRef.list.length == 0) {
              this.$message.warning("请选择改卷老师")
              return
            }
            form.correct_user_id = this.$refs.wholeRef.list.map(item => item.edu_user_id)
          } else {
            let bool = false
            let data = []
            this.$refs.allotRef.list.forEach(item => {
              if (item.edu_user.length == 0) {
                bool = false
              }
              let arr = item.edu_user.map(row => {
                let obj = {
                  question_num: item.question_num,
                  edu_user_id: row.edu_user_id
                }
                return obj;
              })
              data.push(...arr)
            });
            if (bool) {
              this.$message.warning("请选择改卷老师")
              return
            }
            form.paper_correct_data = data
          }
        }
        form.isBrackets = true
        await save(form)
        this.$message.success("成功")
        this.$router.go(-1)
      })
    },
    async preserve () {
      let form = JSON.parse(JSON.stringify(this.form))
      if (this.list.length != 0) {
        form.edu_paper_id = this.list[0].edu_paper_id
      }
      if (this.form.correct_type == 0) {
        form.correct_user_id = this.$refs.wholeRef.list.map(item => item.edu_user_id)
      } else {
        let data = []
        this.$refs.allotRef.list.forEach(item => {
          let arr = item.edu_user.map(row => {
            let obj = {
              question_num: item.question_num,
              edu_user_id: row.edu_user_id
            }
            return obj;
          })
          data.push(...arr)
        });
        form.paper_correct_data = data
      }
      form.keep_id = 1
      form.isBrackets = true
      await save(form)
      this.$message.success("成功")
      this.$router.go(-1)
    },
    async getExamInfo (id) {
      let params = {
        test_paper_id: id
      }
      const { data } = await getInfo(params)
      let edu_paper_data = JSON.parse(JSON.stringify(data.edu_paper_data))
      let list = JSON.parse(JSON.stringify(data.correct_data))

      if (data.correct_type == '0') {
        this.$nextTick(() => {
          this.$refs.wholeRef.list = list
        })
      }
      else {
        // this.$nextTick(() => {
        //   console.log('this.$refs.allotRef', list)
        //   this.$refs.allotRef.list = this.getData(list)
        // })
      }

      delete data.correct_data
      delete data.edu_paper_data
      this.list = [edu_paper_data]
      data.correct_type += ''
      this.form = data

      this.getTeacher()

      if (this.$refs.allotRef.list.length == 0) {
        let params = {
          edu_paper_id: this.form.edu_paper_id
        }
        const { data } = await getQuestionNumber(params)
        this.setQN(data, list)
      }
    },
    toExport (row) {
      // let params = {
      //   edu_paper_id: row.edu_paper_id
      // }
      // this.$http({
      //   url: '/api/v1/combination/paper_print_sheet',
      //   method: 'get',
      //   responseType: "blob",
      //   params
      // }).then(res => {
      //   const a = document.createElement("a")
      //   a.href = window.URL.createObjectURL(res.data)
      //   a.download = `${row.paper_name}答题卡.docx`
      //   a.click()
      // })
      // console.log("export")
      let sheet_id = this.form.paper_correct_type == 0 ? row.sheet_id : row.sheet_id2
      let correct_type = this.form.paper_correct_type == 0 ? 0 : 1
      let status = this.form.paper_correct_type == 0 ? row.sheet_status : row.sheet_status2
      let type = this.paper_module == 1 ? 'autonomously' : ''
      this.$router.push(`/testPapercomposition/makeSheet?id=${row.edu_paper_id}&sheet_id=${sheet_id}&status=${status}&type=${type}&correct_type=${correct_type}`)

    },
    toDetail (row) {
      console.log(row)
      this.$router.push('/testPapercomposition/onlyPaper/info?edu_paper_id=' + row.edu_paper_id)
    }

  }
}
</script>

<style lang="scss" scoped>
.addWrap {
  // width: ;
  margin: 0 auto;
  padding: 0 40px;
  .title {
    margin-bottom: 20px;
    position: relative;
    padding: 0 15px;
    &:before {
      content: "";
      width: 4px;
      height: 16px;
      border-radius: 20px;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      left: 0;
      background: rgb(72, 172, 253);
      margin-right: 20px;
    }
  }
  .blockWrap {
    border: solid #fff 1px;
    padding: 20px;
    margin: 10px 20px 40px;
    border-radius: 10px;
    box-shadow: 2px 1px 8px #ccc;
  }
  .dropWrap {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
  .el-dropdown {
    cursor: pointer;
    margin: 20px;
    margin-bottom: -2px;
    padding: 0px 3px 15px;
    border-bottom: 2px solid rgb(62, 162, 255);
    color: rgb(62, 162, 255);
  }
}
.opertion_image_warp {
  display: inline-block;
}
.operyion_img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
::d-deep .el-tabs__item {
  font-size: 15px;
}
::v-deep .el-table th {
  background: #f2f2f2;
}
</style>