<template>
  <div>
    <el-button type="primary"
               style="margin-bottom:10px"
               @click="addTeacher">添加教师</el-button>
    <el-table :data="list"
              style="width:100%">
      <el-table-column prop="edu_user_id"
                       label="教师ID" />
      <el-table-column prop="edu_user_name"
                       label="教师名称" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="text_btn_danger"
               @click="delItem(scope.$index)">
            删除
          </div>
        </template>
      </el-table-column>
    </el-table>
    <CheckTeacher ref="checkTeacherRef" />
  </div>
</template>

<script>
import CheckTeacher from './checkTeacher.vue'
export default {
  data () {
    return {
      list: [],
    }
  },
  components: {
    CheckTeacher
  },
  methods: {
    delItem (index) {
      console.log(index)
    },
    addTeacher () {
      let teacherList = this.$parent.teacherList
      this.$refs.checkTeacherRef.allData = teacherList
      this.$refs.checkTeacherRef.list1 = JSON.parse(JSON.stringify(this.list))
      this.$refs.checkTeacherRef.total = teacherList.length
      this.$refs.checkTeacherRef.initData()
      this.$refs.checkTeacherRef.dialogVisible = true
    },
    setData (arr) {
      this.list = arr
    }
  }
}
</script>

<style lang="scss" scoped>
</style>